<template>
  <div class="page_container">
    <div class="container">
      <el-card class="analysis_title">
        <div class="analysis_title_top">
          <div class="analysis_title_top_type">
            <div class="title_text">综合面板</div>
            <div>
              <el-radio-group size="mini" v-model="queryInfo.type" @change="changeSearch">
                <el-radio-button :label="1">日</el-radio-button>
                <el-radio-button :label="2">周</el-radio-button>
                <el-radio-button :label="3">月</el-radio-button>
                <el-radio-button :label="4">年</el-radio-button>
              </el-radio-group>
            </div>
            <div style="margin-left: 10px">
              <el-date-picker
                v-show="queryInfo.type == 1"
                size="mini"
                v-model="queryInfo.time"
                type="date"
                placeholder="选择日期"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                @change="changeTime"
              >
              </el-date-picker>
              <el-date-picker
                v-show="queryInfo.type == 2"
                size="mini"
                v-model="week"
                type="week"
                format="yyyy 第 WW 周"
                placeholder="选择周"
                @change="changeWeek"
              >
              </el-date-picker>
              <el-date-picker
                v-show="queryInfo.type == 3"
                size="mini"
                v-model="queryInfo.time"
                type="month"
                placeholder="选择月"
                value-format="yyyy-MM"
                @change="changeTime"
              >
              </el-date-picker>
              <el-date-picker
                v-show="queryInfo.type == 4"
                size="mini"
                v-model="queryInfo.time"
                type="year"
                placeholder="选择年"
                value-format="yyyy"
                @change="changeTime"
              >
              </el-date-picker>
            </div>
          </div>
          <div class="analysis_title_top_time">
            <span>最近更新时间：</span>
            <span>{{ headInfo.updateTime }}</span>
          </div>
        </div>
        <div class="analysis_title_bottom">
          <div>
            <div style="margin-bottom: 20px">推送企业</div>
            <div class="analysis_title_bottom_data">
              <div>
                <img src="../../assets/image/Group 1000001322.png" alt="" />
              </div>
              <div class="analysis_title_bottom_data_number">{{ headInfo.pushCompanyNum }}</div>
              <div class="analysis_title_bottom_data_day">
                <div class="data_day_text" v-if="queryInfo.type == 1">较昨天</div>
                <div class="data_day_text" v-if="queryInfo.type == 2">较上周</div>
                <div class="data_day_text" v-if="queryInfo.type == 3">较上月</div>
                <div class="data_day_text" v-if="queryInfo.type == 4">较上年</div>
                <div
                  class="triangle_up"
                  v-if="headInfo.pushCompanyNum - headInfo.pushCompanyNumAdd >= 0"
                ></div>
                <div
                  class="triangle_down"
                  v-if="headInfo.pushCompanyNum - headInfo.pushCompanyNumAdd < 0"
                ></div>
                <div
                  class="data_day_number_up"
                  v-if="headInfo.pushCompanyNum - headInfo.pushCompanyNumAdd >= 0"
                >
                  {{ headInfo.pushCompanyNum - headInfo.pushCompanyNumAdd }}
                </div>
                <div
                  class="data_day_number_down"
                  v-if="headInfo.pushCompanyNum - headInfo.pushCompanyNumAdd < 0"
                >
                  {{ Math.abs(headInfo.pushCompanyNum - headInfo.pushCompanyNumAdd) }}
                </div>
              </div>
            </div>
          </div>
          <div>
            <div style="margin-bottom: 20px">推送内容</div>
            <div class="analysis_title_bottom_data">
              <div>
                <img src="../../assets/image/Group 1000001327.png" alt="" />
              </div>
              <div class="analysis_title_bottom_data_number">{{ headInfo.shareNum }}</div>
              <div class="analysis_title_bottom_data_day">
                <div class="data_day_text" v-if="queryInfo.type == 1">较昨天</div>
                <div class="data_day_text" v-if="queryInfo.type == 2">较上周</div>
                <div class="data_day_text" v-if="queryInfo.type == 3">较上月</div>
                <div class="data_day_text" v-if="queryInfo.type == 4">较上年</div>
                <div class="triangle_up" v-if="headInfo.shareNum - headInfo.shareNumAdd >= 0"></div>
                <div
                  class="triangle_down"
                  v-if="headInfo.shareNum - headInfo.shareNumAdd < 0"
                ></div>
                <div
                  class="data_day_number_up"
                  v-if="headInfo.shareNum - headInfo.shareNumAdd >= 0"
                >
                  {{ headInfo.shareNum - headInfo.shareNumAdd }}
                </div>
                <div
                  class="data_day_number_down"
                  v-if="headInfo.shareNum - headInfo.shareNumAdd < 0"
                >
                  {{ Math.abs(headInfo.shareNum - headInfo.shareNumAdd) }}
                </div>
              </div>
            </div>
          </div>
          <div>
            <div style="margin-bottom: 20px">查看次数</div>
            <div class="analysis_title_bottom_data">
              <div>
                <img src="../../assets/image/Group 1000001330.png" alt="" />
              </div>
              <div class="analysis_title_bottom_data_number">{{ headInfo.viewNum }}</div>
              <div class="analysis_title_bottom_data_day">
                <div class="data_day_text" v-if="queryInfo.type == 1">较昨天</div>
                <div class="data_day_text" v-if="queryInfo.type == 2">较上周</div>
                <div class="data_day_text" v-if="queryInfo.type == 3">较上月</div>
                <div class="data_day_text" v-if="queryInfo.type == 4">较上年</div>
                <div class="triangle_up" v-if="headInfo.viewNum - headInfo.viewNumAdd >= 0"></div>
                <div class="triangle_down" v-if="headInfo.viewNum - headInfo.viewNumAdd < 0"></div>
                <div class="data_day_number_up" v-if="headInfo.viewNum - headInfo.viewNumAdd >= 0">
                  {{ headInfo.viewNum - headInfo.viewNumAdd }}
                </div>
                <div class="data_day_number_down" v-if="headInfo.viewNum - headInfo.viewNumAdd < 0">
                  {{ Math.abs(headInfo.viewNum - headInfo.viewNumAdd) }}
                </div>
              </div>
            </div>
          </div>
          <div>
            <div style="margin-bottom: 20px">分享次数</div>
            <div class="analysis_title_bottom_data">
              <div>
                <img src="../../assets/image/Group 1000001323.png" alt="" />
              </div>
              <div class="analysis_title_bottom_data_number">{{ headInfo.shareNum }}</div>
              <div class="analysis_title_bottom_data_day">
                <div class="data_day_text" v-if="queryInfo.type == 1">较昨天</div>
                <div class="data_day_text" v-if="queryInfo.type == 2">较上周</div>
                <div class="data_day_text" v-if="queryInfo.type == 3">较上月</div>
                <div class="data_day_text" v-if="queryInfo.type == 4">较上年</div>
                <div class="triangle_up" v-if="headInfo.shareNum - headInfo.shareNumAdd >= 0"></div>
                <div
                  class="triangle_down"
                  v-if="headInfo.shareNum - headInfo.shareNumAdd < 0"
                ></div>
                <div
                  class="data_day_number_up"
                  v-if="headInfo.shareNum - headInfo.shareNumAdd >= 0"
                >
                  {{ headInfo.shareNum - headInfo.shareNumAdd }}
                </div>
                <div
                  class="data_day_number_down"
                  v-if="headInfo.shareNum - headInfo.shareNumAdd < 0"
                >
                  {{ Math.abs(headInfo.shareNum - headInfo.shareNumAdd) }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-card>
      <el-card class="analysis_content">
        <div class="center_search">
          <div>
            <el-radio-group
              class="custom_radio_group"
              v-model="queryInfo.dateType"
              @change="changDateType"
            >
              <el-radio-button class="custom_radio_group_item" :label="1">内容</el-radio-button>
              <el-radio-button class="custom_radio_group_item" :label="2">企业</el-radio-button>
              <el-radio-button class="custom_radio_group_item" :label="3">顾问</el-radio-button>
            </el-radio-group>
            <el-radio-group size="mini" v-model="queryInfo.typePage" @change="changeSearchPage">
              <el-radio-button :label="1">日</el-radio-button>
              <el-radio-button :label="2">周</el-radio-button>
              <el-radio-button :label="3">月</el-radio-button>
              <el-radio-button :label="4">年</el-radio-button>
            </el-radio-group>
            <el-date-picker
              style="margin-left: 10px"
              v-show="queryInfo.typePage == 1"
              size="mini"
              v-model="queryInfo.timePage"
              type="date"
              placeholder="选择日期"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              @change="changeTimePage"
            >
            </el-date-picker>
            <el-date-picker
              style="margin-left: 10px"
              v-show="queryInfo.typePage == 2"
              size="mini"
              v-model="weekPage"
              type="week"
              format="yyyy 第 WW 周"
              placeholder="选择周"
              @change="changeWeekPage"
            >
            </el-date-picker>
            <el-date-picker
              style="margin-left: 10px"
              v-show="queryInfo.typePage == 3"
              size="mini"
              v-model="queryInfo.timePage"
              type="month"
              placeholder="选择月"
              value-format="yyyy-MM"
              @change="changeTimePage"
            >
            </el-date-picker>
            <el-date-picker
              style="margin-left: 10px"
              v-show="queryInfo.typePage == 4"
              size="mini"
              v-model="queryInfo.timePage"
              type="year"
              placeholder="选择年"
              value-format="yyyy"
              @change="changeTimePage"
            >
            </el-date-picker>
          </div>
          <div class="search_right">
            <el-input
              v-if="queryInfo.dateType == 1"
              placeholder="请输入内容名称"
              class="input-with-select"
              clearable
              v-model="queryInfo.queryConditions"
              @change="changeInput"
            >
              <el-button class="el-button" slot="append" @click="changeInputButton">搜索</el-button>
            </el-input>
            <el-input
              v-if="queryInfo.dateType == 2"
              placeholder="请输入企业名称"
              class="input-with-select"
              clearable
              v-model="queryInfo.queryConditions"
              @change="changeInput"
            >
              <el-button class="el-button" slot="append" @click="changeInputButton">搜索</el-button>
            </el-input>
            <el-input
              v-if="queryInfo.dateType == 3"
              placeholder="请输入顾问姓名"
              class="input-with-select"
              clearable
              v-model="queryInfo.queryConditions"
              @change="changeInput"
            >
              <el-button class="el-button" slot="append" @click="changeInputButton">搜索</el-button>
            </el-input>
          </div>
        </div>
        <div class="center_search_type" v-if="queryInfo.dateType == 1">
          <div class="center_search_type_text">内容类型：</div>
          <div>
            <el-radio-group
              size="small"
              v-model="queryInfo.informationType"
              @change="changeInformationType"
            >
              <el-radio-button class="custom_radio_button" label="1">应用场景</el-radio-button>
              <el-radio-button class="custom_radio_button" label="2">数智技术</el-radio-button>
              <el-radio-button class="custom_radio_button" label="3">行业动态</el-radio-button>
              <el-radio-button class="custom_radio_button" label="4">案例视频</el-radio-button>
              <el-radio-button class="custom_radio_button" label="5">数智产品</el-radio-button>
              <el-radio-button class="custom_radio_button" label="6">服务商</el-radio-button>
              <el-radio-button class="custom_radio_button" label="7">政策解读</el-radio-button>
              <el-radio-button class="custom_radio_button" label="8">组织人才</el-radio-button>
              <el-radio-button class="custom_radio_button" label="9">专家观点</el-radio-button>
              <el-radio-button class="custom_radio_button" label="10">数智案例</el-radio-button>
              <el-radio-button class="custom_radio_button" label="11">场景知识</el-radio-button>
            </el-radio-group>
          </div>
        </div>
        <!-- 数据表格——内容表格 -->
        <div class="center_search_table" v-if="queryInfo.dateType == 1">
          <el-table
            class="my_table"
            v-loading="loading"
            element-loading-text="拼命加载中"
            :header-cell-style="{ background: '#F8F9FB', color: '#595959', 'text-align': 'center' }"
            :data="contentTableData"
            style="width: 100%; background-color: #f5f7fb"
            @sort-change="changeContentOrders"
          >
            <el-table-column label="内容名称" width="180">
              <template v-slot="{ row }">
                <el-button @click="goContentDetails(row.id)" type="text">{{
                  row.informationName
                }}</el-button>
              </template>
            </el-table-column>
            <el-table-column align="center" label="内容类型" width="180">
              <template v-slot="{ row }">
                <div v-if="row.informationType == 1">应用场景</div>
                <div v-if="row.informationType == 2">数智技术</div>
                <div v-if="row.informationType == 3">行业动态</div>
                <div v-if="row.informationType == 4">案例视频</div>
                <div v-if="row.informationType == 5">数智产品</div>
                <div v-if="row.informationType == 6">服务商</div>
                <div v-if="row.informationType == 7">政策解读</div>
                <div v-if="row.informationType == 8">组织人才</div>
                <div v-if="row.informationType == 9">专家观点</div>
                <div v-if="row.informationType == 10">数智案例</div>
                <div v-if="row.informationType == 11">场景知识</div>
              </template>
            </el-table-column>
            <el-table-column prop="pushCompanyNum" sortable align="center" label="推送企业数">
            </el-table-column>
            <el-table-column
              prop="userViewNum"
              sortable
              align="center"
              label="用户查看数"
              width="180"
            >
            </el-table-column>
            <el-table-column
              prop="userViewProportion"
              sortable
              align="center"
              label="查看数占比"
              width="180"
            >
              <template v-slot="{ row }">
                <div style="color: #ffa24d">
                  {{ `${row.userViewProportion}%` }}
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="userShareNum" sortable align="center" label="内容被分享数">
            </el-table-column>
            <el-table-column
              prop="userShareProportion"
              sortable
              align="center"
              label="被分享占比"
              width="180"
            >
              <template v-slot="{ row }">
                <div style="color: #ffa24d">
                  {{ `${row.userShareProportion}%` }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="informationCompanyNum"
              align="center"
              label="内容反馈企业"
              width="180"
            >
            </el-table-column>
            <!-- <el-table-column prop="userActivityLevel" sortable align="center" label="用户活跃度">
              <template>
                <div class="customer_status">高</div>
                <div class="customer_status redbg" v-if="row.userActivityLevel">低</div>
                <div class="customer_status yellowbg" v-if="row.userActivityLevel">中</div>
              </template>
            </el-table-column> -->
          </el-table>
        </div>

        <!-- 数据表格——企业表格 -->
        <div class="center_search_table" v-if="queryInfo.dateType == 2">
          <el-table
            class="my_table"
            v-loading="loading"
            element-loading-text="拼命加载中"
            :header-cell-style="{ background: '#F8F9FB', color: '#595959', 'text-align': 'center' }"
            :data="enterpriseTableData"
            style="width: 100%; background-color: #f5f7fb"
            @sort-change="changeEnterpriseOrders"
          >
            <el-table-column align="center" label="企业名称" width="180">
              <template v-slot="{ row }">
                <el-button @click="goEnterpriseDetails(row.id)" type="text">{{
                  row.companyFullName
                }}</el-button>
              </template>
            </el-table-column>
            <el-table-column prop="pushContentNumber" align="center" label="推送内容数">
            </el-table-column>
            <el-table-column prop="serverNum" align="center" label="服务次数">
            </el-table-column>
            <el-table-column prop="viewNum" align="center" label="查看次数">
            </el-table-column>
            <el-table-column prop="shareNum" align="center" label="分享次数">
            </el-table-column>
            <el-table-column prop="responseNum" sortable align="center" label="企业响应次数">
            </el-table-column>
            <el-table-column
              prop="companyGrade"
              sortable
              :sort-method="customSort"
              align="center"
              label="客户等级"
            >
            </el-table-column>
            <el-table-column prop="operationTime" sortable align="center" label="最新运营时间">
              <template v-slot="{ row }">
                <div>{{ row.operationTime | date }}</div>
                <div>{{ row.operationTime | time }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="operationName" align="center" label="运营人员">
            </el-table-column>
            <el-table-column prop="lastLoginTime" sortable align="center" label="上次登录时间">
              <template v-slot="{ row }">
                <div>{{ row.lastLoginTime | date }}</div>
                <div>{{ row.lastLoginTime | time }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="pushTime" sortable align="center" label="上次推送时间">
              <template v-slot="{ row }">
                <div>{{ row.pushTime | date }}</div>
                <div>{{ row.pushTime | time }}</div>
              </template>
            </el-table-column>
            <!-- <el-table-column sortable align="center" label="用户活跃度">
              <template>
                <div class="customer_status">高</div>
                <div class="customer_status redbg">低</div>
                <div class="customer_status yellowbg">中</div>
              </template>
            </el-table-column> -->
          </el-table>
        </div>

        <!-- 数据表格——顾问表格 -->
        <div class="center_search_table" v-if="queryInfo.dateType == 3">
          <el-table
            v-loading="loading"
            element-loading-text="拼命加载中"
            class="my_table"
            :header-cell-style="{ background: '#F8F9FB', color: '#595959', 'text-align': 'center' }"
            :data="adviserTableData"
            style="width: 100%; background-color: #f5f7fb"
            @sort-change="changeAdviserOrders"
          >
            <el-table-column align="center" label="顾问姓名" width="180">
              <template v-slot="{ row }">
                <div>{{ row.userName }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="operationCompanyNum" sortable align="center" label="运营企业数">
              <template v-slot="{ row }">
                <div>{{ row.operationCompanyNum }}</div>
              </template>
            </el-table-column>
            <el-table-column
              prop="operationPointCompanyNum"
              sortable
              align="center"
              label="运营重点企业数"
            >
              <template v-slot="{ row }">
                <div>{{ row.operationPointCompanyNum }}</div>
              </template>
            </el-table-column>
            <el-table-column
              prop="operationWeekCompanyNum"
              sortable
              align="center"
              label="本周运营企业数"
            >
              <template v-slot="{ row }">
                <div>{{ row.operationWeekCompanyNum }}</div>
              </template>
            </el-table-column>
            <el-table-column
              prop="correctionProportion"
              sortable
              align="center"
              label="推送内容相关占比"
            >
              <template v-slot="{ row }">
                <div v-if="row.correctionProportion" style="color: #ffa24d">
                  {{ `${row.correctionProportion}%` }}
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="replyNum" sortable align="center" label="客户回复次数">
              <template v-slot="{ row }">
                <div>{{ row.replyNum }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="activeReplyNum" sortable align="center" label="主动回复次数">
              <template v-slot="{ row }">
                <div>{{ row.activeReplyNum }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="lastServiceTime" sortable align="center" label="最新服务时间">
              <template v-slot="{ row }">
                <div>{{ row.lastServiceTime | date }}</div>
                <div>{{ row.lastServiceTime | time }}</div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-card>
    </div>
    <!-- 分页区域 -->
    <div class="new_page">
      <el-pagination
        :page-sizes="[4, 10, 30]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
import {
  getInformationHeaderNum,
  selectInformationAnalysis,
  selectCompanyAnalysisPage,
  selectAdviserAnalysisPage
} from '@/api/analysis.js'

const defaultQueryInfo = Object.freeze({
  pageNum: 1,
  pageSize: 10,
  companyId: '', // 企业主键
  informationId: '', // 资讯主键
  informationType: '1', // 1应用场景，2数智技术，3行业动态，4案例视频，5数智产品，6服务商,7政策解读,8组织人才,9专家观点,10数智案例,11场景知识
  orders: '1', // 企业主键
  queryConditions: '', // 模糊搜索资讯名称
  type: 1, // 1=日，2=周，3=月，4=年
  weekNum: '', //周
  time: '',
  typePage: 4, //列表；1=日，2=周，3=月，4=年
  timePage: '', //列表；头部；时间
  weekNumPage: '', //列表；列表；第多少周
  dateType: 1 // 数据类型；1=内容，2=企业，3=顾问
})
export default {
  name: 'analysis',
  data() {
    return {
      queryInfo: { ...defaultQueryInfo }, //查询参数
      headInfo: {}, // 头部数据
      contentTableData: [], // 内容表格数据
      enterpriseTableData: [], // 企业表格数据
      adviserTableData: [], // 顾问表格数据
      total: 0, // 总页数
      week: '', // 头部周
      weekPage: '', // 列表周
      loading: false,
      sortDirection: 'ascending'
    }
  },
  created() {
    // 设置头部默认日期
    this.queryInfo.time = this.formattedDate
    this.queryInfo.weekNum = this.formatWeekOfYear(new Date()).split('-')[1]
    // 设置列表默认日期
    this.queryInfo.timePage = this.formattedDatePage
    this.queryInfo.weekNumPage = this.formatWeekOfYear(new Date()).split('-')[1]
    this.loading = true
    this.search()
    this.contentSearch()
  },
  computed: {
    // 头部格式化日期为 'yyyy-mm-dd' 字符串
    formattedDate() {
      const year = new Date().getFullYear()
      const month = (new Date().getMonth() + 1).toString().padStart(2, '0')
      const day = new Date().getDate().toString().padStart(2, '0')
      let newTime
      if (this.queryInfo.type == 1) {
        newTime = `${year}-${month}-${day}`
      } else if (this.queryInfo.type == 3) {
        newTime = `${year}-${month}`
      } else if (this.queryInfo.type == 4) {
        newTime = `${year}`
      } else {
        newTime = `${year}`
      }
      return newTime
    },
    // 列表格式化日期为 'yyyy-mm-dd' 字符串
    formattedDatePage() {
      const year = new Date().getFullYear()
      const month = (new Date().getMonth() + 1).toString().padStart(2, '0')
      const day = new Date().getDate().toString().padStart(2, '0')
      let newTime
      if (this.queryInfo.typePage == 1) {
        newTime = `${year}-${month}-${day}`
      } else if (this.queryInfo.typePage == 3) {
        newTime = `${year}-${month}`
      } else if (this.queryInfo.typePage == 4) {
        newTime = `${year}`
      } else {
        newTime = `${year}`
      }
      return newTime
    }
  },
  // watch: {
  //   'queryInfo.time'(newVal) {
  //     this.queryInfo.time = newVal
  //   },
  //   'queryInfo.weekNum'(newVal) {
  //     this.queryInfo.weekNum = this.formatWeekOfYear(newVal)
  //   }
  // },
  methods: {
    //查询头部一栏
    async search() {
      const { data: res } = await getInformationHeaderNum(this.queryInfo)
      if (res.resultCode === 200) {
        this.headInfo = res.data
      } else {
        this.$message.error('获取失败！')
      }
    },
    // 查询内容列表
    async contentSearch() {
      const { data: res } = await selectInformationAnalysis(this.queryInfo)
      if (res.resultCode === 200) {
        this.loading = false
        this.contentTableData = res.data.list
        this.total = res.data.total
      } else {
        this.$message.error('获取失败！')
      }
    },
    // 查询企业列表
    async enterpriseSearch() {
      const { data: res } = await selectCompanyAnalysisPage(this.queryInfo)
      if (res.resultCode === 200) {
        this.enterpriseTableData = []
        this.loading = false
        this.enterpriseTableData = res.data.list
        this.total = res.data.total
      } else {
        this.$message.error('获取失败！')
      }
    },
    // 查询顾问列表
    async adviserSearch() {
      const { data: res } = await selectAdviserAnalysisPage(this.queryInfo)
      if (res.resultCode === 200) {
        this.loading = false
        this.adviserTableData = res.data.list
        this.total = res.data.total
      } else {
        this.$message.error('获取失败！')
      }
    },
    // 去内容详情页
    goContentDetails(id) {
      this.$router.push({
        path: '/operate/contentDetails',
        query: {
          archiveId: id
        }
      })
    },
    // 去企业详情页
    goEnterpriseDetails(id) {
      this.$router.push({
        path: '/operate/enterpriseDetails',
        query: {
          archiveId: id
        }
      })
    },
    // 计算第几周
    getWeekNumber(date) {
      // 获取一周的第一天（周日）的日期
      const firstDay =
        date.getDay() === 0
          ? date
          : new Date(date.getTime() - ((date.getDay() || 7) - 1) * 86400000)

      // 获取一年第一周的第一天（周日）的日期
      const firstWeekFirstDay = new Date(firstDay.getFullYear(), 0, 1)

      // 如果第一周的第一天不是周日，则将其调整为周日
      if (firstWeekFirstDay.getDay() !== 0) {
        firstWeekFirstDay.setDate(firstWeekFirstDay.getDate() - firstWeekFirstDay.getDay() + 1)
      }

      // 计算偏移量
      const offset = Math.floor((firstDay.getTime() - firstWeekFirstDay.getTime()) / (7 * 86400000))

      // 返回周数（可能为0，表示前一年的最后一周）
      return offset
    },
    formatWeekOfYear(date) {
      if (date instanceof Date) {
        const year = date.getFullYear()
        const weekNumber = this.getWeekNumber(date)
        return `${year}-${weekNumber}`
      }
    },
    // 头部选择框发生改变
    changeSearch() {
      this.queryInfo.time = this.formattedDate
      if (this.queryInfo.type == 2) {
        this.week = new Date()
      }
      this.search()
    },
    // 列表选择框发生改变
    changeSearchPage() {
      this.loading = true
      this.queryInfo.timePage = this.formattedDatePage
      if (this.queryInfo.typePage == 2) {
        this.weekPage = new Date()
      }
      if (this.queryInfo.dateType == 1) {
        this.contentSearch()
      } else if (this.queryInfo.dateType == 2) {
        this.enterpriseSearch()
      } else {
        this.adviserSearch()
      }
    },
    // 头部选择第几周后处理
    changeWeek(val) {
      if (this.queryInfo.type == 2) {
        if (val) {
          this.queryInfo.weekNum = this.formatWeekOfYear(val).split('-')[1]
          this.queryInfo.time = this.formatWeekOfYear(val).split('-')[0]
        } else {
          this.queryInfo.weekNum = ''
          this.queryInfo.time = this.formattedDate
        }
        this.search()
      }
    },
    // 头部选择日期发生变化后
    changeTime(val) {
      if (val) {
        this.queryInfo.time = val
        this.search()
      } else {
        this.queryInfo.time = this.formattedDate
        this.search()
      }
    },
    // 列表类型切换
    changDateType(val) {
      if (val == 1) {
        this.loading = true
        this.contentSearch()
      } else if (val == 2) {
        this.loading = true
        this.enterpriseSearch()
      } else {
        this.loading = true
        this.adviserSearch()
      }
    },
    // 列表选择第几周后处理
    changeWeekPage(val) {
      this.queryInfo.pageNum = 1
      if (this.queryInfo.typePage == 2) {
        if (val) {
          this.queryInfo.weekNumPage = this.formatWeekOfYear(val).split('-')[1]
          this.queryInfo.timePage = this.formatWeekOfYear(val).split('-')[0]
        } else {
          this.queryInfo.weekNumPage = ''
          this.queryInfo.timePage = this.formattedDate
        }
        if (this.queryInfo.dateType == 1) {
          this.contentSearch()
        } else if (this.queryInfo.dateType == 2) {
          this.enterpriseSearch()
        } else {
          this.adviserSearch()
        }
      }
    },
    // 列表选择日期发生变化后
    changeTimePage(val) {
      this.queryInfo.pageNum = 1
      if (val) {
        this.queryInfo.timePage = val
        if (this.queryInfo.dateType == 1) {
          this.contentSearch()
        } else if (this.queryInfo.dateType == 2) {
          this.enterpriseSearch()
        } else {
          this.adviserSearch()
        }
      } else {
        this.queryInfo.timePage = this.formattedDate
        if (this.queryInfo.dateType == 1) {
          this.contentSearch()
        } else if (this.queryInfo.dateType == 2) {
          this.enterpriseSearch()
        } else {
          this.adviserSearch()
        }
      }
    },
    // 模糊查询
    changeInput(val) {
      this.queryInfo.pageNum = 1
      this.queryInfo.queryConditions = val
      if (this.queryInfo.dateType == 1) {
        this.contentSearch()
      } else if (this.queryInfo.dateType == 2) {
        this.enterpriseSearch()
      } else {
        this.adviserSearch()
      }
    },
    // 点击搜索按钮查询
    changeInputButton() {
      this.queryInfo.pageNum = 1
      if (this.queryInfo.dateType == 1) {
        this.contentSearch()
      } else if (this.queryInfo.dateType == 2) {
        this.enterpriseSearch()
      } else {
        this.adviserSearch()
      }
    },
    // 内容类型发生改变后
    changeInformationType(val) {
      this.queryInfo.informationType = val
      this.queryInfo.pageNum = 1
      this.queryInfo.orders = ''
      this.loading = true
      this.contentSearch()
    },
    customSort(a, b) {
      // 定义排序优先级
      const priority = {
        S: 4, // 数字越大，优先级越高
        A: 3,
        B: 2,
        C: 1
      }
      // 获取a和b的优先级
      const priorityA = a.companyGrade
        ? priority[a.companyGrade]
        : this.sortDirection == 'descending'
        ? -Infinity
        : Infinity
      const priorityB = b.companyGrade
        ? priority[b.companyGrade]
        : this.sortDirection == 'descending'
        ? -Infinity
        : Infinity

      // 根据优先级进行排序
      return priorityA - priorityB
    },
    // 内容列表排序
    changeContentOrders(val) {
      switch (val.prop) {
        case 'pushCompanyNum':
          if (val.order == 'ascending') {
            //pushCompanyNum列升序时
            this.queryInfo.orders = '2'
            this.contentSearch()
          } else if (val.order == 'descending') {
            //pushCompanyNum列降序时
            this.queryInfo.orders = '1'
            this.contentSearch()
          } else {
            this.queryInfo.orders = ''
            this.contentSearch()
          }
          break // 可选，用于防止代码自动执行到下一个case
        case 'userViewNum':
          if (val.order == 'ascending') {
            //pushCompanyNum列升序时
            this.queryInfo.orders = '4'
            this.contentSearch()
          } else if (val.order == 'descending') {
            //pushCompanyNum列降序时
            this.queryInfo.orders = '3'
            this.contentSearch()
          } else {
            this.queryInfo.orders = ''
            this.contentSearch()
          }
          break
        case 'userViewProportion':
          if (val.order == 'ascending') {
            //pushCompanyNum列升序时
            this.queryInfo.orders = '6'
            this.contentSearch()
          } else if (val.order == 'descending') {
            //pushCompanyNum列降序时
            this.queryInfo.orders = '5'
            this.contentSearch()
          } else {
            this.queryInfo.orders = ''
            this.contentSearch()
          }
          break
        case 'userShareNum':
          if (val.order == 'ascending') {
            //pushCompanyNum列升序时
            this.queryInfo.orders = '8'
            this.contentSearch()
          } else if (val.order == 'descending') {
            //pushCompanyNum列降序时
            this.queryInfo.orders = '7'
            this.contentSearch()
          } else {
            this.queryInfo.orders = ''
            this.contentSearch()
          }
          break
        case 'userShareProportion':
          if (val.order == 'ascending') {
            //pushCompanyNum列升序时
            this.queryInfo.orders = '10'
            this.contentSearch()
          } else if (val.order == 'descending') {
            //pushCompanyNum列降序时
            this.queryInfo.orders = '9'
            this.contentSearch()
          } else {
            this.queryInfo.orders = ''
            this.contentSearch()
          }
          break
      }
    },
    // 企业列表排序
    changeEnterpriseOrders(val) {
      switch (val.prop) {
        case 'pushContentNumber':
          if (val.order == 'ascending') {
            //pushCompanyNum列升序时
            this.queryInfo.orders = '2'
            this.enterpriseSearch()
          } else if (val.order == 'descending') {
            //pushCompanyNum列降序时
            this.queryInfo.orders = '1'
            this.enterpriseSearch()
          } else {
            this.queryInfo.orders = ''
            this.enterpriseSearch()
          }
          break // 可选，用于防止代码自动执行到下一个case
        case 'serverNum':
          if (val.order == 'ascending') {
            //pushCompanyNum列升序时
            this.queryInfo.orders = '4'
            this.enterpriseSearch()
          } else if (val.order == 'descending') {
            //pushCompanyNum列降序时
            this.queryInfo.orders = '3'
            this.enterpriseSearch()
          } else {
            this.queryInfo.orders = ''
            this.enterpriseSearch()
          }
          break
        case 'viewNum':
          if (val.order == 'ascending') {
            //pushCompanyNum列升序时
            this.queryInfo.orders = '6'
            this.enterpriseSearch()
          } else if (val.order == 'descending') {
            //pushCompanyNum列降序时
            this.queryInfo.orders = '5'
            this.enterpriseSearch()
          } else {
            this.queryInfo.orders = ''
            this.enterpriseSearch()
          }
          break
        case 'shareNum':
          if (val.order == 'ascending') {
            //pushCompanyNum列升序时
            this.queryInfo.orders = '8'
            this.enterpriseSearch()
          } else if (val.order == 'descending') {
            //pushCompanyNum列降序时
            this.queryInfo.orders = '7'
            this.enterpriseSearch()
          } else {
            this.queryInfo.orders = ''
            this.enterpriseSearch()
          }
          break
        case 'reponseNum':
          if (val.order == 'ascending') {
            //pushCompanyNum列升序时
            this.queryInfo.orders = '10'
            this.enterpriseSearch()
          } else if (val.order == 'descending') {
            //pushCompanyNum列降序时
            this.queryInfo.orders = '9'
            this.enterpriseSearch()
          } else {
            this.queryInfo.orders = ''
            this.enterpriseSearch()
          }
          break
        case 'companyGrade':
          if (val.order == 'ascending') {
            //pushCompanyNum列升序时
            this.queryInfo.orders = '12'
            this.sortDirection = 'ascending'
            this.enterpriseSearch()
          } else if (val.order == 'descending') {
            //pushCompanyNum列降序时
            this.queryInfo.orders = '11'
            this.sortDirection = 'descending'
            this.enterpriseSearch()
          } else {
            this.queryInfo.orders = ''
            this.enterpriseSearch()
          }
          break
        case 'operationTime':
          if (val.order == 'ascending') {
            //pushCompanyNum列升序时
            this.queryInfo.orders = '14'
            this.enterpriseSearch()
          } else if (val.order == 'descending') {
            //pushCompanyNum列降序时
            this.queryInfo.orders = '13'
            this.enterpriseSearch()
          } else {
            this.queryInfo.orders = ''
            this.enterpriseSearch()
          }
          break
        case 'lastLoginTime':
          if (val.order == 'ascending') {
            //pushCompanyNum列升序时
            this.queryInfo.orders = '16'
            this.enterpriseSearch()
          } else if (val.order == 'descending') {
            //pushCompanyNum列降序时
            this.queryInfo.orders = '15'
            this.enterpriseSearch()
          } else {
            this.queryInfo.orders = ''
            this.enterpriseSearch()
          }
          break
        case 'pushTime':
          if (val.order == 'ascending') {
            //pushCompanyNum列升序时
            this.queryInfo.orders = '17'
            this.enterpriseSearch()
          } else if (val.order == 'descending') {
            //pushCompanyNum列降序时
            this.queryInfo.orders = '18'
            this.enterpriseSearch()
          } else {
            this.queryInfo.orders = ''
            this.enterpriseSearch()
          }
          break
      }
    },
    // 顾问列表排序
    changeAdviserOrders(val) {
      switch (val.prop) {
        case 'operationCompanyNum':
          if (val.order == 'ascending') {
            //pushCompanyNum列升序时
            this.queryInfo.orders = 2
            this.adviserSearch()
          } else if (val.order == 'descending') {
            //pushCompanyNum列降序时
            this.queryInfo.orders = 1
            this.adviserSearch()
          } else {
            this.queryInfo.orders = ''
            this.adviserSearch()
          }
          break // 可选，用于防止代码自动执行到下一个case
        case 'operationPointCompanyNum':
          if (val.order == 'ascending') {
            //pushCompanyNum列升序时
            this.queryInfo.orders = 4
            this.adviserSearch()
          } else if (val.order == 'descending') {
            //pushCompanyNum列降序时
            this.queryInfo.orders = 3
            this.adviserSearch()
          } else {
            this.queryInfo.orders = ''
            this.adviserSearch()
          }
          break
        case 'operationWeekCompanyNum':
          if (val.order == 'ascending') {
            //pushCompanyNum列升序时
            this.queryInfo.orders = 6
            this.adviserSearch()
          } else if (val.order == 'descending') {
            //pushCompanyNum列降序时
            this.queryInfo.orders = 5
            this.adviserSearch()
          } else {
            this.queryInfo.orders = ''
            this.adviserSearch()
          }
          break
        case 'correctionProportion':
          if (val.order == 'ascending') {
            //pushCompanyNum列升序时
            this.queryInfo.orders = 8
            this.adviserSearch()
          } else if (val.order == 'descending') {
            //pushCompanyNum列降序时
            this.queryInfo.orders = 7
            this.adviserSearch()
          } else {
            this.queryInfo.orders = ''
            this.adviserSearch()
          }
          break
        case 'replyNum':
          if (val.order == 'ascending') {
            //pushCompanyNum列升序时
            this.queryInfo.orders = 10
            this.adviserSearch()
          } else if (val.order == 'descending') {
            //pushCompanyNum列降序时
            this.queryInfo.orders = 9
            this.adviserSearch()
          } else {
            this.queryInfo.orders = ''
            this.adviserSearch()
          }
          break
        case 'activeReplyNum':
          if (val.order == 'ascending') {
            //pushCompanyNum列升序时
            this.queryInfo.orders = 12
            this.adviserSearch()
          } else if (val.order == 'descending') {
            //pushCompanyNum列降序时
            this.queryInfo.orders = 11
            this.adviserSearch()
          } else {
            this.queryInfo.orders = ''
            this.adviserSearch()
          }
          break
        case 'lastServiceTime':
          if (val.order == 'ascending') {
            //pushCompanyNum列升序时
            this.queryInfo.orders = 14
            this.adviserSearch()
          } else if (val.order == 'descending') {
            //pushCompanyNum列降序时
            this.queryInfo.orders = 13
            this.adviserSearch()
          } else {
            this.queryInfo.orders = ''
            this.adviserSearch()
          }
          break
      }
    },
    //监听pagesize变化
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize
      if (this.queryInfo.dateType == 1) {
        this.contentSearch()
      } else if (this.queryInfo.dateType == 2) {
        this.enterpriseSearch()
      } else {
        this.adviserSearch()
      }
    },
    //监听页码变化
    handleCurrentChange(newCurrent) {
      this.queryInfo.pageNum = newCurrent
      if (this.queryInfo.dateType == 1) {
        this.contentSearch()
      } else if (this.queryInfo.dateType == 2) {
        this.enterpriseSearch()
      } else {
        this.adviserSearch()
      }
    }
  }
}
</script>
<style scoped>
.custom_radio_button /deep/ .el-radio-button__inner {
  border: none;
  margin-right: 5px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 550;
}
.custom_radio_group_item /deep/ .el-radio-button__inner {
  font-size: 14px;
  font-weight: 550;
}
</style>
<style lang="less" scoped>
.page_container {
  width: 100%;
  overflow: hidden;
  .container {
    height: calc(100vh - 150px);
    overflow: auto;
    &::-webkit-scrollbar {
    width: 3px;
    height: 9px;
  }
  /* 滚动条里面的滑块 */
  &::-webkit-scrollbar-thumb {
    background-color: rgba(144, 147, 153, 0.3);
    border-radius: 20px;
    transition: background-color 0.3s;
  }
    .analysis_title {
      height: 220px;
      margin-bottom: 10px;

      .analysis_title_top {
        display: flex;
        justify-content: space-between;
        margin: 16px 0;
        .analysis_title_top_type {
          display: flex;
          align-items: center;
        }
        .title_text {
          font-size: 20px;
          font-weight: 550;
          // margin-right: 20px;
          border-left: 3px solid #448aff;
          padding: 8px 10px 8px 20px;
        }
        .analysis_title_top_time {
          margin-right: 18px;
          color: #6b738d;
          font-size: 14px;
        }
      }
      .analysis_title_bottom {
        margin-top: 40px;
        display: flex;
        justify-content: space-around;

        .analysis_title_bottom_data {
          display: flex;
          justify-content: space-between;
          .analysis_title_bottom_data_number {
            font-size: 35px;
            font-weight: 500;
            margin: auto 10px;
          }
          .analysis_title_bottom_data_day {
            display: flex;
            justify-content: space-between;
            margin: auto 10px;
            .data_day_text {
              font-size: 14px;
              margin: auto 10px;
              color: #6b738d;
            }
            .triangle_up {
              width: 0;
              height: 0;
              border-left: 5px solid transparent; /* 左边框透明 */
              border-right: 5px solid transparent; /* 右边框透明 */
              border-bottom: 8px solid #26c297; /* 底边框为红色，并设置为你想要的大小 */
              margin: auto; /* 居中显示，并添加一些上边距 */
            }
            .triangle_down {
              width: 0;
              height: 0;
              border-left: 5px solid transparent; /* 左边框透明 */
              border-right: 5px solid transparent; /* 右边框透明 */
              border-top: 8px solid red; /* 底边框为红色，并设置为你想要的大小 */
              margin: auto; /* 居中显示，并添加一些上边距 */
            }
            .data_day_number_up {
              margin: auto 5px;
              color: #26c297;
            }
            .data_day_number_down {
              margin: auto 5px;
              color: red;
            }
          }
        }
      }
    }
    .analysis_content {
      overflow-y: hidden; /* 内容过多时显示滚动条 */
      .custom_radio_group {
        margin-right: 10px;
      }
      .center_search {
        // margin-top: 10px;
        padding: 0 0 0 20px;
        margin: 16px 0;
        border-left: 3px solid #448aff;
        display: flex;
        justify-content: space-between; /* 两端对齐 */
        .search_right {
          width: 560px;
          margin-right: 18px;
          .el-button {
            border: 1px solid #448aff;
            color: #fff;
            border-radius: 0;
            background-color: #448aff;
          }
        }
      }
      .center_search_type {
        display: flex;
        align-items: center;
        margin: 20px 16px;
        .center_search_type_text {
          color: #595959;
          font-size: 14px;
          font-weight: 570;
        }
      }
      .center_search_table {
        margin: 20px 16px;
        .customer_status {
          background: #48dc5f;
          width: 46px;
          height: 20px;
          line-height: 20px;
          text-align: center;
          color: #fff;
          border-radius: 9px;
          display: inline-block;
          margin: 0 10px;
        }
        .yellowbg {
          background: #ffa24d;
        }
        .redbg {
          background: #ff4d4d;
        }
      }
    }
  }

  .new_page {
    margin: 20px auto;
    width: 100%;
    text-align: center;
  }
}
::v-deep .el-card__body{
  padding: 0;
}
.my_table {
  /* 设置最小高度 */
  min-height: calc(100vh - 150px); /* 根据需要调整 */
  /* 可选：设置背景色或其他样式 */
  background-color: transparent !important;
}
</style>
